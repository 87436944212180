import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaGithub,
  FaLinkedin,
  FaArrowUp,
  FaArrowDown,
  FaEnvelope,
} from "react-icons/fa";

function App() {
  const [currentSection, setCurrentSection] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const sections = [
    {
      title: "Mohsen Bahaeddini",
      subtitle: "Building systems, solving problems, questioning defaults.",
    },
    {
      title: "[01] Who Am I?",
      content:
        "A software engineer, system architect, and problem solver. I design and build software that works at scale.",
    },
    {
      title: "[02] What I Do?",
      content: (
        <ul className="space-y-2 text-content">
          <li>• Architecting software and databases</li>
          <li>• Building cloud-based systems</li>
          <li>• Exploring AI, IoT, and automation</li>
        </ul>
      ),
    },
    {
      title: "[03] No Finish Line.",
      content: (
        <>
          <p className="text-content mb-8">
            Building, learning, exploring. The best ideas start as
            conversations. Let's talk!
          </p>
          <div className="flex justify-center space-x-6">
            <a
              href="https://github.com/MohsenBahaeddini"
              target="_blank"
              rel="noopener noreferrer"
              className="link text-2xl"
            >
              <FaGithub />
            </a>
            <a
              href="https://www.linkedin.com/in/mohsen-bahaeddini/"
              target="_blank"
              rel="noopener noreferrer"
              className="link text-2xl"
            >
              <FaLinkedin />
            </a>
            <a href="mailto:mbahaeddini@gmail.com" className="link text-2xl">
              <FaEnvelope />
            </a>
          </div>
        </>
      ),
    },
  ];

  const handleClick = () => {
    if (currentSection < sections.length - 1) {
      goToNextSection();
    }
  };

  const goToNextSection = () => {
    if (currentSection < sections.length - 1 && !isTransitioning) {
      setIsTransitioning(true);
      setCurrentSection((prev) => prev + 1);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
    }
  };

  const goToPreviousSection = () => {
    if (currentSection > 0 && !isTransitioning) {
      setIsTransitioning(true);
      setCurrentSection((prev) => prev - 1);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
    }
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        goToNextSection();
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        goToPreviousSection();
      }
    },
    [goToNextSection, goToPreviousSection]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="h-screen w-screen overflow-hidden bg-dark">
      <AnimatePresence mode="wait">
        <motion.section
          key={currentSection}
          className="section"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          onClick={handleClick}
        >
          <motion.div className="text-center">
            <h1 className="heading">{sections[currentSection].title}</h1>
            {sections[currentSection].subtitle && (
              <p className="subheading">{sections[currentSection].subtitle}</p>
            )}
            {sections[currentSection].content &&
              sections[currentSection].content}
          </motion.div>
        </motion.section>
      </AnimatePresence>
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4">
        {currentSection > 0 && (
          <button
            onClick={goToPreviousSection}
            className="bg-gray-800 text-white px-4 py-2 rounded"
            disabled={isTransitioning}
          >
            <FaArrowUp />
          </button>
        )}
        {currentSection < sections.length - 1 && (
          <button
            onClick={goToNextSection}
            className="bg-gray-800 text-white px-4 py-2 rounded"
            disabled={isTransitioning}
          >
            <FaArrowDown />
          </button>
        )}
      </div>
    </div>
  );
}

export default App;
